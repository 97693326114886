import type {
  GridListTypeAndProps,
  UnstyledListTypeAndProps
} from '@aurora/shared-client/components/common/List';
import { ListItemSpacing, ListVariant } from '@aurora/shared-client/components/common/List/enums';
import { PagerVariant } from '@aurora/shared-client/components/common/Pager/enums';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import type { TextVariantsContextInterface } from '@aurora/shared-client/components/context/TextVariantContext';
import type { CheckFieldSpec } from '@aurora/shared-client/components/form/CheckField/CheckField';
import {
  FormCheckInputType,
  FormFieldVariant,
  FormInputFieldInputType
} from '@aurora/shared-client/components/form/enums';
import type { IconRadioSpec } from '@aurora/shared-client/components/form/IconRadioField/IconRadioField';
import type { InputFieldSpec } from '@aurora/shared-client/components/form/InputField/InputField';
import type {
  MultiCheckFieldOption,
  MultiCheckFieldSpec
} from '@aurora/shared-client/components/form/MultiCheckField/MultiCheckField';
import type { PillRadioSpec } from '@aurora/shared-client/components/form/PillRadioField/PillRadioField';
import type { RangeFieldSpec } from '@aurora/shared-client/components/form/RangeField/RangeField';
import type { SelectFieldSpec } from '@aurora/shared-client/components/form/SelectField/SelectField';
import Icons from '@aurora/shared-client/icons';
import { UserScope } from '@aurora/shared-client/types/enums';
import type {
  Board,
  QueryMessagesArgs,
  User
} from '@aurora/shared-generated/types/graphql-schema-types';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  ContextNodeFragment,
  ContextUserFragment
} from '@aurora/shared-generated/types/graphql-types';
import type { ClampLinesType } from '@aurora/shared-types/community/enums';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import type { FormatMessage } from '@aurora/shared-types/texts';
import { createObjectByPath } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { MessageListTabItem } from '../../../components/messages/MessageListTabs/MessageListTabs';
import type {
  MessageViewCardProps,
  MessageViewCommonProps,
  MessageViewInlineProps,
  MessageViewStandardProps,
  MessageViewVariantProps
} from '../../../components/messages/MessageView/types';
import type {
  MessageListWidgetConfigurationFormData,
  MessageListWidgetExtendedProps,
  MessageTabItem
} from '../../../components/messages/types';
import { WidgetType } from '../../../components/messages/types';
import { MessageTimestamp, MessageViewVariant } from '../../../types/enums';
import type { MessageViewFragment } from '../../../types/graphql-types';
import getGridListColumns from '../../util/GridListColumnsHelper';
import type { NodePickerFieldSpec } from '@aurora/shared-client/components/form/NodePickerField/NodePickerField';
import type { NodePickerNodeNoParent } from '@aurora/shared-client/components/nodepicker/types';

const messageViewInlineDefaults: MessageViewInlineProps = {
  useAvatar: true,
  useUnreadCount: true,
  useViewCount: true,
  renderPostTimeBeforeAuthor: false
};

const messageViewCardDefaults: MessageViewCardProps = {
  useCenteredContent: false,
  portraitClampBodyLines: 3,
  landscapeClampBodyLines: 3,
  useAvatar: false,
  useViewCount: true,
  useClickableCard: true
};

const messageViewCommonDefaults: MessageViewCommonProps = {
  useSearchSnippet: false,
  useAuthorLogin: true,
  clampBodyLines: 3,
  useBoardIcon: false,
  useAuthorRank: false,
  useKudosCount: true,
  useMedia: true,
  usePreviewMedia: true,
  useTags: true,
  useNode: true,
  useNodeLink: true,
  useTextBody: true,
  truncateBodyLength: 200,
  useBody: true,
  useRepliesCount: true,
  useSolvedBadge: true,
  useTimeToRead: true,
  timeStampType: MessageTimestamp.POST_TIME,
  clampSubjectLines: 2,
  useSpoilerFreeBody: true,
  useNodeHoverCard: true,
  useMessageTimeLink: true
};

type MessageViewInlineKeys = keyof MessageViewInlineProps;
type MessageViewCardKeys = keyof MessageViewCardProps;

const previewLengthDefault = 3;

export default class MessageListWidgetHelper {
  private extendedProps: MessageListWidgetExtendedProps;

  private contextBoard: Board;

  private contextUser: ContextUserFragment;

  private widgetType: WidgetType;

  private formatMessage: FormatMessage;

  private useIdeas: boolean;

  private contextNode: ContextNodeFragment;

  private viewVariantProps:
    | MessageViewInlineProps
    | MessageViewCardProps
    | MessageViewStandardProps;

  constructor(
    props: MessageListWidgetExtendedProps,
    contextBoard: Board,
    contextUser: ContextUserFragment,
    widgetType: WidgetType,
    formatMessage: FormatMessage,
    useIdeas: boolean,
    contextNode: ContextNodeFragment
  ) {
    this.extendedProps = props;
    this.viewVariantProps = props.viewVariant.props;
    this.contextBoard = contextBoard;
    this.contextUser = contextUser;
    this.widgetType = widgetType;
    this.formatMessage = formatMessage;
    this.useIdeas = useIdeas;
    this.contextNode = contextNode;
  }

  /**
   * Field spec for the Node Picker
   *
   * @param nodeScope the node to scope the widget to
   * @param contextNode the context node
   * @returns Field spec for the NodeScope field
   */
  getNodeScopeField(
    nodeScope: NodePickerNodeNoParent | null,
    contextNode: ContextNodeFragment
  ): NodePickerFieldSpec<'nodeScope', MessageListWidgetConfigurationFormData> {
    return {
      fieldVariant: FormFieldVariant.NODE_PICKER,
      name: 'nodeScope',
      isVisible: {
        watchFields: [],
        callback: () => {
          return this.contextNode.nodeType === NodeType.COMMUNITY;
        }
      },
      defaultValue: nodeScope,
      useLightVariant: true,
      onClose(value, setValue) {
        if (value === null) {
          setValue(contextNode);
        }
      }
    };
  }

  /**
   * Returns the range field for page size.
   */
  getWidgetPageSizeField(): RangeFieldSpec<'pageSize', MessageListWidgetConfigurationFormData> {
    return {
      name: 'pageSize',
      fieldVariant: FormFieldVariant.RANGE,
      showOutput: true,
      defaultValue: this.extendedProps.pageSize,
      min: 1,
      max: 20
    };
  }

  getFilterOptionsField(
    defaultValue
  ): MultiCheckFieldSpec<'filterOptions', MessageListWidgetConfigurationFormData> {
    return {
      name: 'filterOptions',
      defaultValue: defaultValue,
      fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
      inputType: FormCheckInputType.SWITCH,
      options: [
        {
          name: 'addTags'
        }
      ],
      isVisible: {
        watchFields: ['style'],
        callback: formData => {
          const { style: displayStyle } = formData;
          return displayStyle === 'list';
        }
      }
    };
  }

  /**
   * Returns the inputFieldSpec for title.
   *
   * @param titleToUse the default value for the title input.
   * @param titlePlaceholder the placeholder value for the title input.
   */
  static getWidgetTitleField(
    titleToUse: string,
    titlePlaceholder: string
  ): InputFieldSpec<'title', MessageListWidgetConfigurationFormData> {
    return {
      name: 'title',
      fieldVariant: FormFieldVariant.INPUT,
      inputType: FormInputFieldInputType.TEXT,
      defaultValue: titleToUse ?? '',
      attributes: {
        placeholder: titlePlaceholder
      }
    };
  }

  /**
   * Returns the inputFieldSpec for hidden title context variant.
   *
   * @param titleContextVariant the default value for the title context variant input.
   */
  static getWidgetHiddenTitleContextVariantField(
    titleContextVariant: string
  ): InputFieldSpec<'titleContextVariant', MessageListWidgetConfigurationFormData> {
    return {
      name: 'titleContextVariant',
      fieldVariant: FormFieldVariant.INPUT,
      inputType: FormInputFieldInputType.TEXT,
      defaultValue: titleContextVariant ?? '',
      formGroupSpec: {
        label: false
      },
      attributes: {
        hidden: true
      }
    };
  }

  /**
   * Returns the checkFieldSpec whether to show title only for screen readers or not.
   */
  static getWidgetShowTitleForScreenReaderOnlyField(
    showOnlyForScreenReader: boolean
  ): CheckFieldSpec<'useTitle', MessageListWidgetConfigurationFormData> {
    return {
      name: 'useTitle',
      fieldVariant: FormFieldVariant.CHECK,
      inputType: FormCheckInputType.SWITCH,
      defaultValue: showOnlyForScreenReader
    };
  }

  /**
   * Returns the detailed list,card,list items to show in configuration section.
   */
  getWidgetListItemMap(): MultiCheckFieldOption<
    keyof MessageViewInlineProps,
    'viewPropsInline',
    MessageListWidgetConfigurationFormData
  >[] {
    const detailedListItems: MessageViewInlineKeys[] = [
      'useAuthorLogin',
      'useBody',
      'usePreviewMedia',
      'useNode',
      'useRepliesCount',
      'useTags',
      'timeStampType',
      'useUnreadCount',
      'useViewCount'
    ];

    const cardItems: MessageViewCardKeys[] = [
      'useAuthorLogin',
      'useBody',
      'useCenteredContent',
      'useRepliesCount',
      'usePreviewMedia',
      'useNodeLink',
      'useTags',
      'timeStampType',
      'useViewCount'
    ];

    const listItems: MessageViewInlineKeys[] = ['useAuthorLogin', 'timeStampType'];

    if (this.contextBoard?.nodeType === NodeType.BOARD) {
      messageViewCommonDefaults.useNode = false;
    }

    if (this.contextUser) {
      messageViewCommonDefaults.useTags = false;
      messageViewCommonDefaults.useTimeToRead = false;
      messageViewCommonDefaults.useAuthorRank = false;
      messageViewCommonDefaults.useAuthorLogin = false;
      messageViewCommonDefaults.useBoardIcon = true;
      messageViewCommonDefaults.useAvatar = false;
      messageViewInlineDefaults.useViewCount = false;
      messageViewCardDefaults.useViewCount = false;
    }

    return {
      card: cardItems,
      list: detailedListItems,
      compact: listItems
    }[this.extendedProps.style].map(item => ({
      name: item
    }));
  }

  /**
   * Returns the SelectFieldSpec data for sort by field.
   */
  getWidgetSortField(): SelectFieldSpec<'sorts', MessageListWidgetConfigurationFormData> {
    return {
      name: 'sorts',
      fieldVariant: FormFieldVariant.SELECT,
      defaultValue: this.extendedProps.sorts,
      isVisible: {
        watchFields: ['showTabsFilter', 'style'],
        callback: formData => {
          const { showTabsFilter, style: myStyle } = formData;
          return !showTabsFilter?.showTabs || myStyle !== 'list';
        }
      },
      values: this.extendedProps.sortByFieldValues.map(item => ({
        key: item.key,
        value: item.value,
        label:
          this.useIdeas &&
          item.key === 'kudosSumWeight' &&
          !this.contextBoard &&
          this.isDiscussionStyleAllOrSpecific(ConversationStyle.Idea)
            ? this.formatMessage('tabs.mostKudoed.containerNode')
            : null,
        isVisible: {
          watchFields: ['conversationStyle'],
          callback: formData => {
            const { conversationStyle } = formData;
            if (this.contextUser) {
              switch (item.key) {
                case 'kudosSumWeight': {
                  return true;
                }
                case 'recentActivityForUser':
                case 'newest': {
                  return this.widgetType === WidgetType.RECENT;
                }
                default: {
                  return false;
                }
              }
            } else if (this.widgetType === WidgetType.RECENT) {
              return item.key !== 'recentActivityForUser';
            } else if (item.key === 'trending') {
              return (
                this.widgetType === WidgetType.TOP && conversationStyle === ConversationStyle.Idea
              );
            }
            return true;
          }
        }
      }))
    };
  }

  /**
   * Returns the IconRadioSpec layout field.
   */
  getWidgetStyleField(): IconRadioSpec<'style', MessageListWidgetConfigurationFormData> {
    return {
      name: 'style',
      fieldVariant: FormFieldVariant.ICON_RADIO,
      values: [
        {
          key: 'list',
          value: 'list',
          icon: Icons.ContentLayoutDetailedListIcon
        },
        {
          key: 'compact',
          value: 'compact',
          icon: Icons.ContentLayoutListIcon
        },
        {
          key: 'card',
          value: 'card',
          icon: Icons.ContentLayoutCardIcon
        }
      ],
      defaultValue: this.extendedProps.style
    };
  }

  /**
   * Returns the RangeFieldSpec data for preview text length field.
   */
  getWidgetPreviewTextLengthListField(): RangeFieldSpec<
    'previewLengthInline',
    MessageListWidgetConfigurationFormData
  > {
    return {
      name: 'previewLengthInline',
      fieldVariant: FormFieldVariant.RANGE,
      showOutput: true,
      defaultValue: this.viewVariantProps.clampBodyLines,
      min: 1,
      max: 5,
      isVisible: {
        watchFields: ['style'],
        callback: formData => {
          const { style: myStyle, viewPropsInline: myProps } = formData;
          return myStyle === 'list' && myProps?.useBody;
        }
      }
    };
  }

  /**
   * Returns @true if the discussion style for widget is All or Discussions
   */
  isDiscussionStyleAllOrSpecific(conversationStyle: ConversationStyle): boolean {
    return this.contextBoard
      ? this.contextBoard.conversationStyle === conversationStyle
      : !this.extendedProps.conversationStyle ||
          this.extendedProps.conversationStyle === conversationStyle;
  }

  /**
   * Returns the RangeFieldSpec data for preview text length field - card layout.
   * @param defaultValue default value of preview text length for card layout.
   */
  static getWidgetPreviewTextLengthCardField(
    defaultValue: ClampLinesType
  ): RangeFieldSpec<'previewLengthCard', MessageListWidgetConfigurationFormData> {
    return {
      name: 'previewLengthCard',
      fieldVariant: FormFieldVariant.RANGE,
      showOutput: true,
      defaultValue: defaultValue ?? previewLengthDefault,
      min: 1,
      max: 5,
      isVisible: {
        watchFields: ['style'],
        callback: formData => {
          const { style: myStyle, viewPropsCard: myProps } = formData;
          return myStyle === 'card' && myProps?.useBody;
        }
      }
    };
  }

  /**
   * Returns the IconRadioSpec data for panel type.
   */
  getWidgetPanelTypeField(): PillRadioSpec<'panelType', MessageListWidgetConfigurationFormData> {
    return {
      name: 'panelType',
      fieldVariant: FormFieldVariant.PILL_RADIO,
      isVisible: {
        watchFields: ['style'],
        callback: formData => {
          const { style: myStyle } = formData;
          return myStyle !== 'card';
        }
      },
      values: [
        {
          key: PanelType.STANDARD,
          value: PanelType.STANDARD
        },
        {
          key: PanelType.DIVIDER,
          value: PanelType.DIVIDER
        },
        {
          key: PanelType.BUBBLE,
          value: PanelType.BUBBLE
        }
      ],
      defaultValue: this.extendedProps.panelType
    };
  }

  /**
   * Returns the extra elements based on the Discussion style.
   */
  getWidgetViewExtendedPropsOptions(): MultiCheckFieldOption<
    keyof MessageViewInlineProps,
    'viewPropsInline',
    MessageListWidgetConfigurationFormData
  >[] {
    return [
      ...this.getWidgetListItemMap(),
      {
        name: 'useKudosCount',
        label:
          this.useIdeas &&
          !this.contextBoard &&
          this.isDiscussionStyleAllOrSpecific(ConversationStyle.Idea)
            ? this.formatMessage('useKudosCount.label')
            : null
      },
      { name: 'useAuthorRank' },
      {
        name: 'useTimeToRead',
        isVisible: {
          watchFields: null,
          callback: () => {
            return this.contextBoard
              ? this.contextBoard.conversationStyle === ConversationStyle.Blog
              : !this.extendedProps.conversationStyle ||
                  this.extendedProps.conversationStyle === ConversationStyle.Blog;
          }
        }
      },
      {
        name: 'useSolvedBadge',
        isVisible: {
          watchFields: null,
          callback: () => {
            return this.isDiscussionStyleAllOrSpecific(ConversationStyle.Forum);
          }
        }
      }
    ];
  }

  /**
   * Returns the MultiCheckFieldSpec data for inline view props field.
   * @param typedInlineProps
   * @param useRanks
   */
  getWidgetViewPropsInlineField(
    typedInlineProps: MessageViewVariantProps<MessageViewVariant.INLINE>
  ): MultiCheckFieldSpec<'viewPropsInline', MessageListWidgetConfigurationFormData> {
    if (typedInlineProps.usePreviewMedia === undefined) {
      typedInlineProps.usePreviewMedia = true;
      typedInlineProps.useMedia = true;
    }
    return {
      name: 'viewPropsInline',
      fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
      inputType: FormCheckInputType.SWITCH,
      options: this.getWidgetViewExtendedPropsOptions(),
      defaultValue: typedInlineProps,
      isVisible: {
        watchFields: ['style'],
        callback: formData => {
          const { style: myStyle } = formData;
          return myStyle === 'list';
        }
      }
    };
  }

  /**
   * Returns the MultiCheckFieldSpec data for compact view props field.
   * @param typedInlineProps
   */
  getWidgetViewPropsCompactField(
    typedInlineProps: MessageViewVariantProps<MessageViewVariant.INLINE>
  ): MultiCheckFieldSpec<'viewPropsCompact', MessageListWidgetConfigurationFormData> {
    if (typedInlineProps.usePreviewMedia === undefined) {
      typedInlineProps.usePreviewMedia = false;
      typedInlineProps.useMedia = false;
    }
    return {
      name: 'viewPropsCompact',
      fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
      inputType: FormCheckInputType.SWITCH,
      options: this.getWidgetListItemMap(),
      defaultValue: typedInlineProps,
      isVisible: {
        watchFields: ['style'],
        callback: formData => {
          const { style: myStyle } = formData;
          return myStyle === 'compact';
        }
      }
    };
  }

  /**
   * Returns the MultiCheckFieldSpec data for card view props field.
   * @param typedCardProps
   * @param useRanks
   */
  getWidgetViewPropsCardField(
    typedCardProps: MessageViewVariantProps<MessageViewVariant.CARD>
  ): MultiCheckFieldSpec<'viewPropsCard', MessageListWidgetConfigurationFormData> {
    if (typedCardProps.usePreviewMedia === undefined) {
      typedCardProps.usePreviewMedia = true;
      typedCardProps.useMedia = true;
    }
    return {
      name: 'viewPropsCard',
      fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
      inputType: FormCheckInputType.SWITCH,
      options: this.getWidgetViewExtendedPropsOptions() as MultiCheckFieldOption<
        keyof MessageViewCardProps,
        'viewPropsCard',
        MessageListWidgetConfigurationFormData
      >[],
      defaultValue: typedCardProps,
      isVisible: {
        watchFields: ['style'],
        callback: formData => {
          const { style: myStyle } = formData;
          return myStyle === 'card';
        }
      }
    };
  }

  /**
   * Returns the SelectFieldSpec data for Icon field.
   * @param defaultValue
   */
  static getWidgetIconField(
    defaultValue: 'avatar' | 'icon' | 'none'
  ): SelectFieldSpec<'leadIcon', MessageListWidgetConfigurationFormData> {
    return {
      name: 'leadIcon',
      fieldVariant: FormFieldVariant.SELECT,
      defaultValue,
      isVisible: {
        watchFields: ['style'],
        callback: formData => {
          const { style: myStyle } = formData;
          return myStyle !== 'card';
        }
      },
      values: [
        {
          key: 'icon',
          value: 'icon'
        },
        {
          key: 'avatar',
          value: 'avatar'
        },
        {
          key: 'none',
          value: 'none'
        }
      ]
    };
  }

  /**
   * Returns the MultiCheckFieldSpec data for More Options field.
   * @param defaultValue
   */
  static getMoreOptionsField(
    defaultValue
  ): MultiCheckFieldSpec<'moreOptions', MessageListWidgetConfigurationFormData> {
    return {
      name: 'moreOptions',
      fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
      inputType: FormCheckInputType.SWITCH,
      defaultValue,
      options: [
        {
          name: 'hideIfEmpty',
          isVisible: {
            watchFields: ['showTabsFilter', 'style'],
            callback: formData => {
              const { showTabsFilter: tabState, style: myStyle } = formData;
              return (!tabState?.showTabs && myStyle === 'list') || myStyle !== 'list';
            }
          }
        },
        {
          name: 'pagerOption',
          isVisible: {
            watchFields: ['style'],
            callback: formData => {
              const { style: myStyle } = formData;
              return myStyle !== 'card';
            }
          }
        },
        {
          name: 'pagerOptionCard',
          isVisible: {
            watchFields: ['showTabsFilter'],
            callback: formData => {
              const { style: myStyle } = formData;
              return myStyle === 'card';
            }
          }
        },
        {
          name: 'lazyLoad'
        }
      ]
    };
  }

  /**
   * Returns the SelectFieldSpec data for Discussion style field.
   */
  getWidgetDiscussionStyleField(): SelectFieldSpec<
    'conversationStyle',
    MessageListWidgetConfigurationFormData
  > {
    return {
      name: 'conversationStyle',
      fieldVariant: FormFieldVariant.SELECT,
      defaultValue: this.extendedProps.conversationStyle,
      isVisible: {
        watchFields: ['nodeScope'],
        callback: formData => {
          return (
            this.contextBoard?.nodeType !== NodeType.BOARD &&
            formData.nodeScope?.nodeType !== NodeType.BOARD
          );
        }
      },
      values: [
        {
          key: 'All',
          value: null
        },
        {
          key: 'blog',
          value: ConversationStyle.Blog
        },
        {
          key: 'idea',
          value: ConversationStyle.Idea,
          isVisible: {
            watchFields: [],
            callback: () => this.useIdeas
          }
        },
        {
          key: 'tkb',
          value: ConversationStyle.Tkb
        },
        {
          key: 'forum',
          value: ConversationStyle.Forum
        }
      ]
    };
  }

  /**
   * Returns the MultiCheckFieldSpec data for show tabs option.
   */
  getShowTabsFilterField(
    defaultValue
  ): MultiCheckFieldSpec<'showTabsFilter', MessageListWidgetConfigurationFormData> {
    return {
      name: 'showTabsFilter',
      defaultValue: defaultValue,
      fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
      inputType: FormCheckInputType.SWITCH,
      options: [
        {
          name: 'showTabs'
        }
      ],
      isVisible: {
        watchFields: ['style'],
        callback: formData => {
          const { style: displayStyle } = formData;
          return displayStyle === 'list';
        }
      }
    };
  }

  /**
   * Returns the CheckFieldSpec data for show tabs option.
   */
  getShowTabsField(): CheckFieldSpec<'showTabs', MessageListWidgetConfigurationFormData> {
    return {
      name: 'showTabs',
      fieldVariant: FormFieldVariant.CHECK,
      inputType: FormCheckInputType.SWITCH,
      defaultValue: this.extendedProps?.showTabs,
      isVisible: {
        watchFields: ['style'],
        callback: formData => {
          const { style: displayStyle } = formData;
          return displayStyle === 'list';
        }
      }
    };
  }

  /**
   * Returns the MultiCheckFieldSpec data for Default Tabs .
   */
  getDefaultTabsField(): MultiCheckFieldSpec<
    'defaultTabs',
    MessageListWidgetConfigurationFormData
  > {
    return {
      name: 'defaultTabs',
      fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
      inputType: FormCheckInputType.SWITCH,
      defaultValue: this.extendedProps?.tabItemMap?.default,
      isVisible: {
        watchFields: ['showTabsFilter', 'style'],
        callback: formData => {
          const { showTabsFilter: tabState, style: myStyle } = formData;
          return tabState?.showTabs && myStyle === 'list';
        }
      },
      options: Object.keys(this.extendedProps?.tabItemMap?.default).map(item => ({
        name: item,
        label: this.getTabLabel(item),
        isVisible: {
          watchFields: ['conversationStyle'],
          callback: formData => {
            const { conversationStyle } = formData;
            if (this.contextUser) {
              if (this.widgetType === WidgetType.TOP) {
                return MessageListTabItem.MOST_KUDOED === item;
              }
              if (this.widgetType === WidgetType.RECENT) {
                return MessageListTabItem.MOST_RECENT !== item;
              }
            } else if (item === MessageListTabItem.TRENDING) {
              return conversationStyle === ConversationStyle.Idea;
            }
            return !(
              this.widgetType === WidgetType.RECENT &&
              (MessageListTabItem.MOST_KUDOED === item ||
                MessageListTabItem.MOST_RECENT_USER_CONTENT === item)
            );
          }
        }
      }))
    };
  }

  /**
   * To fetch the tabs to be shown.
   */
  fetchTabs(): MessageListTabItem[] {
    const tabList = [];
    if (this.extendedProps.showTabs) {
      const tabs = {
        ...this.extendedProps?.tabItemMap?.default,
        ...this.extendedProps?.tabItemMap?.additional
      };
      Object.keys(tabs).forEach(key => {
        if (
          tabs[key] &&
          this.isTabAllowedOnDiscussionStyle(key) &&
          this.isTabAllowedOnNodePage(key) &&
          this.isTabAllowedOnUserPage(key)
        ) {
          tabList.push(key);
        }
      });
    }
    return tabList;
  }

  /**
   * Returns the label of the given tab item
   * @param item tab item
   */
  getTabLabel(item: string): string {
    if (this.useIdeas && !this.contextBoard) {
      switch (item) {
        case MessageListTabItem.MOST_KUDOED: {
          return this.isDiscussionStyleAllOrSpecific(ConversationStyle.Idea)
            ? this.formatMessage('tabs.mostKudoed.containerNode')
            : null;
        }
        case MessageListTabItem.NEWEST: {
          return this.extendedProps.conversationStyle === ConversationStyle.Idea
            ? this.formatMessage('tabs.newest.ideas.label')
            : null;
        }
        default: {
          return null;
        }
      }
    }
    return null;
  }

  /**
   * Returns the MultiCheckFieldSpec data for Additional Tabs.
   */
  getAdditionalTabsField(
    additionalTabs: MessageTabItem
  ): MultiCheckFieldSpec<'additionalTabs', MessageListWidgetConfigurationFormData> {
    return {
      name: 'additionalTabs',
      fieldVariant: FormFieldVariant.MULTI_CHECK_BOX,
      inputType: FormCheckInputType.SWITCH,
      defaultValue: this.extendedProps.tabItemMap?.additional,
      isVisible: {
        watchFields: ['showTabsFilter', 'style'],
        callback: formData => {
          const { showTabsFilter: tabState, style: myStyle } = formData;
          return tabState?.showTabs && myStyle === 'list';
        }
      },
      options: Object.keys({
        ...this.extendedProps.tabItemMap?.additional,
        ...additionalTabs
      }).map(item => ({
        name: item,
        label: this.getTabLabel(item),
        isVisible: {
          watchFields: ['conversationStyle'],
          callback: formData => {
            const { conversationStyle } = formData;
            if (this.contextUser) {
              switch (item) {
                case MessageListTabItem.SOLUTIONS: {
                  return this.isDiscussionStyleAllOrSpecific(ConversationStyle.Forum);
                }
                case MessageListTabItem.MOST_KUDOED: {
                  return this.widgetType === WidgetType.RECENT;
                }
                case MessageListTabItem.MOST_RECENT_USER_CONTENT:
                case MessageListTabItem.NEWEST: {
                  return this.widgetType === WidgetType.TOP;
                }
                default: {
                  return false;
                }
              }
            } else {
              switch (item) {
                case MessageListTabItem.NO_SOLUTIONS: {
                  return this.isDiscussionStyleAllOrSpecific(ConversationStyle.Forum);
                }
                case MessageListTabItem.SOLUTIONS: {
                  return this.isDiscussionStyleAllOrSpecific(ConversationStyle.Forum);
                }
                case MessageListTabItem.MOST_RECENT_USER_CONTENT: {
                  return false;
                }
                case MessageListTabItem.TRENDING: {
                  return conversationStyle === ConversationStyle.Idea;
                }
                default: {
                  return true;
                }
              }
            }
          }
        }
      }))
    };
  }

  /**
   * Returns the default values for card view.
   */
  static getMessageViewCardDefaults(): MessageViewCardProps {
    return messageViewCardDefaults;
  }

  /**
   * Returns the default values for inline compact view.
   */
  static getMessageViewInlineDefaults(): MessageViewInlineProps {
    return messageViewInlineDefaults;
  }

  /**
   * Returns the common default values for any view.
   */
  static getMessageViewCommonDefaults(): MessageViewCommonProps {
    return messageViewCommonDefaults;
  }

  /**
   * Returns the updated props form data.
   * @param data current form data.
   */
  static getTransformToWidgetProps(
    data: MessageListWidgetConfigurationFormData
  ): MessageListWidgetExtendedProps {
    const {
      style: selectedStyle,
      panelType: selectedPanelType,
      pageSize: selectedPageSize,
      conversationStyle: selectedConversationStyle,
      previewLengthInline: selectedPreviewLengthInline,
      previewLengthCard: selectedPreviewLengthCard,
      viewPropsInline,
      viewPropsCompact,
      viewPropsCard,
      useTitle: selectedHideTitle,
      sorts: selectedSort,
      leadIcon: selectedLeadIcon,
      moreOptions: selectedOptions,
      showTabsFilter,
      filterOptions,
      defaultTabs: selectedDefaultTabs,
      additionalTabs: selectedAdditionalTabs,
      titleContextVariant: selectedTitleContextVariant,
      nodeScope: selectedNodeScope
    } = data;
    const currentProps = {} as MessageListWidgetExtendedProps;
    const listVariantGrid: GridListTypeAndProps<MessageViewFragment> = {
      type: ListVariant.GRID,
      props: {
        itemSpacing: ListItemSpacing.LG,
        colProps: getGridListColumns(selectedPageSize)
      }
    };

    const listVariantUnstyled: UnstyledListTypeAndProps<MessageViewFragment> = {
      type: ListVariant.UNSTYLED
    };

    if (selectedStyle === 'card') {
      const clampBody = {
        portraitClampBodyLines:
          selectedPreviewLengthCard || messageViewCardDefaults.portraitClampBodyLines,
        landscapeClampBodyLines:
          selectedPreviewLengthCard || messageViewCardDefaults.landscapeClampBodyLines
      };
      currentProps.viewVariant = {
        type: MessageViewVariant.CARD,
        props: {
          ...messageViewCardDefaults,
          ...messageViewCommonDefaults,
          ...viewPropsCard,
          ...clampBody
        }
      };
      if (selectedOptions.pagerOptionCard) {
        currentProps.pagerVariant = { type: PagerVariant.LOAD_MORE };
      } else {
        currentProps.pagerVariant = { type: PagerVariant.NONE };
      }
      if (selectedPageSize > 2) {
        currentProps.listVariant = listVariantGrid;
      } else {
        currentProps.listVariant = listVariantUnstyled;
      }
      currentProps.panelType = PanelType.SPACED;
    } else if (selectedStyle === 'list') {
      currentProps.viewVariant = {
        type: MessageViewVariant.INLINE,
        props: {
          ...messageViewInlineDefaults,
          ...messageViewCommonDefaults,
          ...viewPropsInline
        }
      };
      if (selectedOptions.pagerOption) {
        currentProps.pagerVariant = { type: PagerVariant.LOAD_MORE };
      } else {
        currentProps.pagerVariant = { type: PagerVariant.NONE };
      }
    } else {
      currentProps.viewVariant = {
        type: MessageViewVariant.INLINE,
        props: {
          ...messageViewInlineDefaults,
          ...messageViewCommonDefaults,
          ...viewPropsCompact
        }
      };
      if (selectedOptions?.pagerOption) {
        currentProps.pagerVariant = { type: PagerVariant.LOAD_MORE };
      } else {
        currentProps.pagerVariant = { type: PagerVariant.NONE };
      }
    }

    if (selectedStyle !== 'card') {
      currentProps.viewVariant.props.useAvatar = selectedLeadIcon === 'avatar';
      currentProps.viewVariant.props.useBoardIcon = selectedLeadIcon === 'icon';
      currentProps.viewVariant.props.clampBodyLines =
        selectedPreviewLengthInline || previewLengthDefault;
      currentProps.panelType = selectedPanelType || PanelType.DIVIDER;
      if (currentProps.panelType !== PanelType.DIVIDER) {
        createObjectByPath(currentProps, 'listVariant', {
          type: ListVariant.UNSTYLED,
          props: {
            listItemSpacing:
              currentProps.panelType === PanelType.STANDARD
                ? ListItemSpacing.XXL
                : ListItemSpacing.MD
          }
        });
      }
    }

    if (currentProps.viewVariant.props.timeStampType) {
      if (selectedSort?.conversationLastPostingActivityTime) {
        currentProps.viewVariant.props.timeStampType = MessageTimestamp.LAST_POSTING_ACTIVITY_TIME;
      } else if (selectedSort?.lastPublishTime) {
        currentProps.viewVariant.props.timeStampType = MessageTimestamp.LAST_PUBLISHED_TIME;
      } else {
        currentProps.viewVariant.props.timeStampType = MessageTimestamp.POST_TIME;
      }
    }

    currentProps.pageSize = selectedPageSize;
    currentProps.useTitle = !selectedHideTitle;
    currentProps.sorts = selectedSort;
    currentProps.hideIfEmpty = selectedOptions?.hideIfEmpty;
    currentProps.lazyLoad = selectedOptions?.lazyLoad;
    currentProps.conversationStyle = selectedConversationStyle;
    currentProps.style = selectedStyle;

    currentProps.showTabs = selectedStyle === 'list' ? showTabsFilter?.showTabs : false;
    currentProps.addTags = selectedStyle === 'list' ? filterOptions?.addTags : false;

    if (selectedDefaultTabs) {
      createObjectByPath(currentProps, 'tabItemMap', {
        default: selectedDefaultTabs,
        additional: selectedAdditionalTabs
      });
    }
    currentProps.titleContextVariant = selectedTitleContextVariant;
    if (selectedNodeScope) {
      currentProps.nodeScopeId = selectedNodeScope.id;
    } else if (selectedNodeScope === null) {
      // allow the node scope to be cleared if it has been previously set
      currentProps.nodeScopeId = null;
    }
    return currentProps;
  }

  /**
   * Checks whether a tab is allowed for a specific discussion style.
   * @param key the tab enum to check
   */
  isTabAllowedOnDiscussionStyle(key: string): boolean {
    if (key === MessageListTabItem.TRENDING) {
      return this.extendedProps.conversationStyle === ConversationStyle.Idea;
    }
    return !(
      !this.isDiscussionStyleAllOrSpecific(ConversationStyle.Forum) &&
      (key === MessageListTabItem.NO_SOLUTIONS || key === MessageListTabItem.SOLUTIONS)
    );
  }

  /**
   * Checks whether a tab is allowed on a node page.
   * @param key the tab enum to check
   */
  isTabAllowedOnNodePage(key: string): boolean {
    return !(!this.contextUser && key === MessageListTabItem.MOST_RECENT_USER_CONTENT);
  }

  /**
   * Checks whether a tab is allowed on the user page.
   * @param key the tab enum to check
   */
  isTabAllowedOnUserPage(key: string): boolean {
    return !(
      this.contextUser &&
      (key === MessageListTabItem.MOST_VIEWED ||
        key === MessageListTabItem.MOST_REPLIES ||
        key === MessageListTabItem.MOST_RECENT)
    );
  }

  /**
   * Returns a new text variant based on the variables passed in.
   */
  static getTextVariantForMessageQueryVariables(
    variables: QueryMessagesArgs,
    currentTextContext: TextVariantsContextInterface,
    currentUser: Pick<User, 'id'>
  ): TextVariantsContextInterface {
    const userScope =
      variables?.constraints?.authorId?.eq === currentUser.id ? UserScope.SELF : UserScope.OTHER;

    let newTextContext: TextVariantsContextInterface = {
      ...currentTextContext,
      userScope
    };

    if (variables?.constraints?.conversationStyle?.eq != null) {
      newTextContext = {
        ...newTextContext,
        board: { conversationStyle: variables.constraints.conversationStyle.eq }
      };
    }

    return newTextContext;
  }
}
